@import url("https://fonts.cdnfonts.com/css/agency-fb");

.font-agency {
  color: white;
  font-family: "Agency FB", sans-serif;
  letter-spacing: 3px;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
}
