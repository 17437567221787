@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/inter');


* {
  font-family: Inter;
}