/* index.css or styles.css */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.topg {
  margin-top: 75px;
  margin-bottom: 60px;
  padding: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(20px);
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

.fade-out {
  animation: fadeOut 0.5s;
}

.carousel-wrapper {
  width: 100%;
}

.carousel-slide {
  position: relative;
}

.carousel-image {
  width: 100%;
  height: auto;
}

.carousel-text {
  background-color: rgba(255, 0, 0, 0.5); /* Changed to red with 50% opacity */
  backdrop-filter: blur(4px) brightness(80%);
  /* border-radius: 0 50px 50px 0; */
}

.carousel-text-last {
  background-color: rgba(255, 0, 0); /* Changed to red with 50% opacity */
  backdrop-filter: blur(4px) brightness(80%);}

.img-car {
  height: 500px;
}

.carousel-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.carousel-description {
  font-size: 1.5rem;
}
/* carousel.css */
.carousel-overlay {
  width: 750px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.text--red {
  color: red !important;
}

.img-car {
  height: 500px;
  width: 100%;
  object-fit: cover; /* Ensures the aspect ratio is maintained */
}

/* carousel.css */
/* Existing CSS Rules */

@media (max-width: 768px) {
  .carousel-overlay {
    width: 100% !important;
  }

  .img-car {
    height: 300px;
  }

  .carousel-overlay {
    padding: 1rem;
  }

  .text-2xl {
    font-size: 1.5rem;
  }
}
